@tailwind base;

@layer base {
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: #fafafa;
    color: #4b6464;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  ul,
  ol {
    @apply list-disc list-item pl-10;
  }

  ul::marker {
    content: none;
  }

  .policy-doc-wrap & > div + div {
    margin-top: 10px;
  }

  .policy-button-wrap & > a + a {
    margin-left: 20px;
  }

  .react-tel-input .flag-dropdown:hover .selected-flag {
    background-color: #eaeaea;
    cursor: default;
  }
}

@tailwind components;
@tailwind utilities;
